.header {
    background-color: honeydew;
    color: black;
    max-width: 100%;
    padding: 50px;
    h2 {
        margin: 0;
        padding: 0;
        font-size: 4em;
        text-align: center;
    }
}